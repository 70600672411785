import React from "react";
import { Navigate } from "react-router-dom";

const isAuthenticated = (token) => {
  if (!token) {
    return false;
  }
  return true;
};

function AdminProtected({ Component }) {
  const token = localStorage.getItem("lapp_admin_token");
  return isAuthenticated(token) ? <Component /> : <Navigate to="/" />;
}

export default AdminProtected;
