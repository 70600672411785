import { configureStore } from "@reduxjs/toolkit";
import categoryReducer from "./categorySlice";
import subcategoryReducer from "./subCategorySlice";
import judgementsReducer from "./judgementsSlice";
import provisionsReducer from "./provisionsSlice";
import usersReducer from "./usersSlice";
import litigationsReducer from "./litigationSlice";
import formReducer from "./formSlice";

export const store = configureStore({
  reducer: {
    categories: categoryReducer,
    subcategories: subcategoryReducer,
    judgements: judgementsReducer,
    provisions: provisionsReducer,
    users: usersReducer,
    litigations: litigationsReducer,
    forms: formReducer,
  },
});
