import { createSlice } from "@reduxjs/toolkit";

const judgements = JSON.parse(localStorage.getItem("judgements")) || [];
const initialState = {
  value: judgements,
};

export const judgementSlice = createSlice({
  name: "judgements",
  initialState,
  reducers: {
    setJudgements: (state, action) => {
      state.value = action.payload;
      localStorage.setItem("judgements", JSON.stringify(action.payload));
    },
  },
});

export const { setJudgements } = judgementSlice.actions;

export default judgementSlice.reducer;
