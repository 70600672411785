import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: [],
};

export const formSlice = createSlice({
  name: "forms",
  initialState,
  reducers: {
    setForms: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setForms } = formSlice.actions;
export default formSlice.reducer;
