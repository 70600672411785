import { createSlice } from "@reduxjs/toolkit";
const litigations = JSON.parse(localStorage.getItem("litigations")) || [];

const initialState = {
  value: litigations,
};

export const litigationsSlice = createSlice({
  name: "litigations",
  initialState,
  reducers: {
    setLitigations: (state, action) => {
      state.value = action.payload;
      localStorage.setItem("litigations", JSON.stringify(action.payload));
    },
  },
});

export const { setLitigations } = litigationsSlice.actions;
export default litigationsSlice.reducer;
