import { createSlice } from "@reduxjs/toolkit";

// const categories = [];
const categories = JSON.parse(localStorage.getItem("categories")) || [];

const initialState = {
  value: categories || [],
};

export const categorySlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    setCategories: (state, action) => {
      localStorage.removeItem("categories");
      state.value = action.payload;
      localStorage.setItem("categories", JSON.stringify(action.payload));
      // state.value = JSON.parse(localStorage.getItem("categories")) || [];
    },
    addCategory: (state, action) => {
      const categories = JSON.parse(localStorage.getItem("categories")) || [];
      const category = {
        category: action.payload,
        subcategories: [],
      };
      categories.push(category);
      localStorage.setItem("categories", JSON.stringify(categories));
    },
    editCategory: (state, action) => {
      const categories = JSON.parse(localStorage.getItem("categories"));
      const categoryId = action.payload.categoryId;
      const updatedCategory = action.payload.updatedCategory;
      categories[categoryId].category = updatedCategory;
      localStorage.setItem("categories", JSON.stringify(categories));
    },
    deleteCategory: (state, action) => {
      const categories = JSON.parse(localStorage.getItem("categories"));
      categories.splice(action.payload, 1);
      localStorage.setItem("categories", JSON.stringify(categories));
    },
  },
});

export const { setCategories, addCategory, deleteCategory, editCategory } =
  categorySlice.actions;

export default categorySlice.reducer;
