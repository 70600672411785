import { createSlice } from "@reduxjs/toolkit";

// const categories = JSON.parse(localStorage.getItem("categories"));

const initialState = {
  value: null,
};

export const subCategorySlice = createSlice({
  name: "subcategories",
  initialState,
  reducers: {
    setSubCategories: (state, action) => {
      state.value =
        JSON.parse(localStorage.getItem("categories"))[action.payload.catIdx]
          .subcategories || [];
    },
    addSubCategory: (state, action) => {
      const categories = JSON.parse(localStorage.getItem("categories")) || [];
      const catIdx = action.payload.catIdx;
      const subCategory = {
        subcategory: action.payload.subcategory,
        articles: [],
      };
      categories[catIdx].subcategories.push(subCategory);
      localStorage.setItem("categories", JSON.stringify(categories));
    },
    editSubCategory: (state, action) => {
      const categories = JSON.parse(localStorage.getItem("categories")) || [];
      const categoryId = action.payload.categoryId;
      const subcategoryId = action.payload.subcategoryId;
      const updatedSubCategory = action.payload.updatedSubCategory;
      categories[categoryId].subcategories[subcategoryId].subcategory =
        updatedSubCategory;
      localStorage.setItem("categories", JSON.stringify(categories));
    },
    deleteSubCategory: (state, action) => {
      const categories = JSON.parse(localStorage.getItem("categories")) || [];
      const catIdx = action.payload.catIdx;
      categories[catIdx].subcategories.splice(action.payload.subCatIdx, 1);
      localStorage.setItem("categories", JSON.stringify(categories));
    },
  },
});

export const {
  setSubCategories,
  addSubCategory,
  deleteSubCategory,
  editSubCategory,
} = subCategorySlice.actions;

export default subCategorySlice.reducer;
