import { createSlice } from "@reduxjs/toolkit";
const users = JSON.parse(localStorage.getItem("users")) || [];

const initialState = {
  value: users,
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUsers: (state, action) => {
      state.value = action.payload;
      localStorage.setItem("users", JSON.stringify(action.payload));
    },
  },
});

export const { setUsers } = usersSlice.actions;
export default usersSlice.reducer;
