import { createSlice } from "@reduxjs/toolkit";

const provisions = JSON.parse(localStorage.getItem("provisions")) || [];
const initialState = {
  value: provisions,
};
export const provisionsSlice = createSlice({
  name: "provisions",
  initialState,
  reducers: {
    setProvisions: (state, action) => {
      state.value = action.payload;
      localStorage.setItem("provisions", JSON.stringify(action.payload));
    },
  },
});

export const { setProvisions } = provisionsSlice.actions;
export default provisionsSlice.reducer;
